// App.js
import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import { AuthProvider } from "./AuthContext"; // Adjust the import path as necessary
import keepme from "./assets/buttons/svgKeepMe/KeepMe_logo_fullcolor_rgb.svg";
import { useTranslation } from "react-i18next";

const DisplayTemplate = lazy(
  () => import("./components/ProfilePageComp/Templates/DisplayTemplate")
);
const RedirectToNewRoute = lazy(
  () => import("./components/RedirectToNewRoute")
);
const Return = lazy(() => import("./components/Stripe/Return"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const BagOlymp = lazy(() => import("./components/BagOlymp/BagOlymp"));
const Homepage = lazy(() => import("./components/Homepage"));
const SignIn = lazy(() => import("./components/WelcomeComp/SignIn"));
const SignUp = lazy(() => import("./components/WelcomeComp/SignUp"));
const ProfilePage = lazy(() => import("./components/ProfilePage"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const SetPassword = lazy(() => import("./components/SetPassword"));
const ProfileContextProvider = lazy(
  () => import("./components/Context/ProfileContext")
);
const PasswordChange = lazy(
  () => import("./components/WelcomeComp/PasswordChange")
);
const ActivationSuccess = lazy(
  () => import("./components/WelcomeComp/ActivationSuccess")
);
const RegistrationSuccess = lazy(
  () => import("./components/WelcomeComp/RegistrationSuccess")
);
const PasswordReset = lazy(
  () => import("./components/WelcomeComp/PasswordReset")
);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Explicitly set language to English on initial load
    i18n.changeLanguage("en");
  }, []);

  const isHomepage = window.location.pathname === "/";

  return (
    <AuthProvider>
      <Router>
        <Suspense
          fallback={
            <div
              className={`app-suspense ${isHomepage ? "bg-[#FFD6EF]" : "bg-[#F9F9F9]"}`}
            >
              <img className="app-suspense-img" src={keepme} alt="Keep Me" />
            </div>
          }
        >
          <ProfileContextProvider>
            <div className="App">
              <Routes>
                <Route path="/" element={<Homepage keepme={keepme} />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/registration-success"
                  element={<RegistrationSuccess />}
                />
                <Route
                  path="/activate/:token"
                  element={<ActivationSuccess />}
                />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route
                  path="/change-password/:token"
                  element={<PasswordChange />}
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                />

                {/* Old routes that need redirection */}
                <Route
                  path="/card/:companyName/:username"
                  element={<RedirectToNewRoute />}
                />
                <Route
                  path="/card/:companyName"
                  element={<RedirectToNewRoute />}
                />
                {/* New routes */}
                <Route
                  path="/:companyName/:username"
                  element={<DisplayTemplate />}
                />
                <Route path="/:companyName" element={<DisplayTemplate />} />
                <Route path="/bag/olymp" element={<BagOlymp />} />
                <Route path="/set-password/:token" element={<SetPassword />} />
                <Route
                  path="/return"
                  element={
                    <PrivateRoute>
                      <Return />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </ProfileContextProvider>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
